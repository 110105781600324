import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '@shared/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ColumnsService {

  private http = inject(HttpClient);
  private _auth = inject(AuthService);

  role: any = [];
  colsOrders: any = null;
  colsRequests: any = null;
  colsClients: any = null;
  colsMedicines: any = null;
  colsFollowups: any = null;
  loadedColumns: boolean = false;

  async initColumns(){
    this.http.get("assets/data/colsOrders.json").subscribe((cols: any) => {
      this.colsOrders = cols;
      //this.colsOrders = cols.countries.filter(c => c.country == this._ps.codPais)[0];
    })
    this.http.get("assets/data/colsRequests.json").subscribe((cols: any) => {
      this.colsRequests = cols;
      //this.colsRequests = cols.countries.filter(c => c.country == this._ps.codPais)[0];
    })
    this.http.get("assets/data/colsClients.json").subscribe((cols: any) => {
      this.colsClients = cols;
      //this.colsClients = cols.countries.filter(c => c.country == this._ps.codPais)[0];
    })
    this.http.get("assets/data/colsMedicines.json").subscribe((cols: any) => {
      this.colsMedicines = cols;
      //this.colsMedicines = cols.countries.filter(c => c.country == this._ps.codPais)[0];
    })
    this.http.get("assets/data/colsFollowups.json").subscribe((cols: any) => {
      this.colsFollowups = cols;
      //this.colsMedicines = cols.countries.filter(c => c.country == this._ps.codPais)[0];
    })
  }

  loadColumnsState(): Promise<boolean>{
    return new Promise((resolve, reject) => {
      if(this.colsOrders && this.colsRequests && this.colsClients && this.colsMedicines && this.colsFollowups){
        this.loadedColumns = true;
        resolve(true)
      } else {
        this.loadedColumns = false;
        resolve(false)
      }
    })
  }

  async getCols(_module: string, state: string){ 
    this.role = this._auth.getUserRole()
    switch(_module){
        case 'ORDERS':
          switch(state){
            case '6':
              return !this.role.includes('ROLE_ADMIN') ? await this.colsOrders.VALIDATE__ROLE_PHARMACY_USER : await this.colsOrders.VALIDATE__ROLE_ADMIN;
            case '9':
              return !this.role.includes('ROLE_ADMIN') ? await this.colsOrders.PACK_OFF__ROLE_PHARMACY_USER : await this.colsOrders.PACK_OFF__ROLE_ADMIN;
            case '11':
              return !this.role.includes('ROLE_ADMIN') ? await this.colsOrders.IN_DISTRIBUTION__ROLE_PHARMACY_USER : await this.colsOrders.IN_DISTRIBUTION__ROLE_ADMIN;
            case '10':
              return !this.role.includes('ROLE_ADMIN') ? await this.colsOrders.DELIVERED__ROLE_PHARMACY_USER : await this.colsOrders.DELIVERED__ROLE_ADMIN;
            case '3':
              return !this.role.includes('ROLE_ADMIN') ? await this.colsOrders.WAITING_FOR_PAYMENT__ROLE_PHARMACY_USER : await this.colsOrders.WAITING_FOR_PAYMENT__ROLE_ADMIN;
            case '8':
              return !this.role.includes('ROLE_ADMIN') ? await this.colsOrders.PROBLEM_INFO_USER__ROLE_PHARMACY_USER : await this.colsOrders.PROBLEM_INFO_USER__ROLE_ADMIN;
            case '5':
              return !this.role.includes('ROLE_ADMIN') ? await this.colsOrders.DELETED_USER__ROLE_PHARMACY_USER : await this.colsOrders.DELETED__ROLE_ADMIN;
            case '4':
              return !this.role.includes('ROLE_ADMIN') ? await this.colsOrders.CANCEL_BY_USER__ROLE_PHARMACY_USER : await this.colsOrders.CANCEL_BY_USER__ROLE_ADMIN;
            default:
              return !this.role.includes('ROLE_ADMIN') ? await this.colsOrders.ALL__ROLE_PHARMACY_USER : await this.colsOrders.ALL__ROLE_ADMIN;
          }  
        
        case 'REQUESTS':
          switch(state){
            case '':
                return !this.role.includes('ROLE_ADMIN') ? await this.colsRequests.ALL__ROLE_DOCTOR_USER : await this.colsRequests.ALL__ROLE_ADMIN;
            case 'ALL':
                return !this.role.includes('ROLE_ADMIN') ? await this.colsRequests.ALL__ROLE_DOCTOR_USER : await this.colsRequests.ALL__ROLE_ADMIN;
            case 'VALIDATE':
                return !this.role.includes('ROLE_ADMIN') ? await this.colsRequests.VALIDATE__ROLE_DOCTOR_USER : await this.colsRequests.VALIDATE__ROLE_ADMIN;
            case 'TELEMEDICINE':
                return !this.role.includes('ROLE_ADMIN') ? await this.colsRequests.TELEMEDICINE__ROLE_DOCTOR_USER : await this.colsRequests.TELEMEDICINE__ROLE_ADMIN;
            case 'APPROVED':
                return !this.role.includes('ROLE_ADMIN') ? await this.colsRequests.APPROVED__ROLE_DOCTOR_USER : await this.colsRequests.APPROVED__ROLE_ADMIN;
            case 'REJECTED':
                return !this.role.includes('ROLE_ADMIN') ? await this.colsRequests.REJECTED__ROLE_DOCTOR_USER : await this.colsRequests.REJECTED__ROLE_ADMIN;
            default:
            return [];
          }
        
        case 'FOLLOWUPS':
          switch(state){
            case '':
                return !this.role.includes('ROLE_ADMIN') ? await this.colsRequests.ALL__ROLE_DOCTOR_USER : await this.colsRequests.ALL__ROLE_ADMIN;
            case 'ALL':
                return !this.role.includes('ROLE_ADMIN') ? await this.colsRequests.ALL__ROLE_DOCTOR_USER : await this.colsRequests.ALL__ROLE_ADMIN;
            case 'VALIDATE':
                return !this.role.includes('ROLE_ADMIN') ? await this.colsRequests.VALIDATE__ROLE_DOCTOR_USER : await this.colsRequests.VALIDATE__ROLE_ADMIN;
            case 'TELEMEDICINE':
                return !this.role.includes('ROLE_ADMIN') ? await this.colsRequests.TELEMEDICINE__ROLE_DOCTOR_USER : await this.colsRequests.TELEMEDICINE__ROLE_ADMIN;
            case 'APPROVED':
                return !this.role.includes('ROLE_ADMIN') ? await this.colsRequests.APPROVED__ROLE_DOCTOR_USER : await this.colsRequests.APPROVED__ROLE_ADMIN;
            case 'REJECTED':
                return !this.role.includes('ROLE_ADMIN') ? await this.colsRequests.REJECTED__ROLE_DOCTOR_USER : await this.colsRequests.REJECTED__ROLE_ADMIN;
            default:
            return [];
          }
        
        case 'CLIENTS':
          return await this.colsClients && this.colsClients.ALL__ROLE_ADMIN
          
        case 'CHATS':
            
          return [];
        
        case 'MEDICINES':
            
          return await this.colsMedicines && this.colsMedicines.ALL__ROLE_ADMIN;
        
        default:
            return [];
    }
  }


}
